<template>
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">change contact us</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-text-field
              dense
              outlined
              label=" Name"
              v-model="singleSocila.name"
              :error-messages="errors.name"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Email"
              v-model="singleSocila.email"
              :error-messages="errors.email"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Phone"
              v-model="singleSocila.phone"
              :error-messages="errors.phone"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Description"
              v-model="singleSocila.description"
              :error-messages="errors.description"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Twitter"
              v-model="singleSocila.twitter"
              :error-messages="errors.twitter"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Whatsapp"
              v-model="singleSocila.whatsapp"
              :error-messages="errors.whatsapp"
            ></v-text-field>
             <v-text-field
              dense
              outlined
              dir="ltr"
              label="Telegram"
              v-model="singleSocila.telegram"
              :error-messages="errors.telegram"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Instagram"
              v-model="singleSocila.instagram"
              :error-messages="errors.instagram"
            ></v-text-field>
             <v-text-field
              dense
              outlined
              dir="ltr"
              label="Snapchat"
              v-model="singleSocila.snapchat"
              :error-messages="errors.snapchat"
            ></v-text-field>
          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="updateProfile(singleSocila)"
          >حفظ</v-btn
        >
      </v-card-actions>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name : "Edit-Social",
  data: function() {
    return {
      form: {},
      errors: {},
      formLoading: false
    };
  },

 


  methods: {
    ...mapActions('social' ,["fetchContact", "updateContact"]),
     updateProfile(singleSocila) {
      this.formLoading = true;

      this.$store
        .dispatch("social/updateContact", { singleSocila })
        .then(response => {
          this.$bus.$emit("showSnackbar", {
            text: "تم تعديل بياناتك بنجاح",
            color: "success"
          });
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "خطا اثناء تعديل بياناتك",
            color: "accent"
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
  computed: mapGetters('social' ,["singleSocila"]),
  created() {
    this.fetchContact();
  },
}
</script>
